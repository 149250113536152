<template>
    <div id="checkStockPreOrder" class="container-fluid m-0 p-0">
        <div class="row m-0 p-0 justify-content-center">
            <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
                <div class="row m-0 p-4">
                    <div class="col-12 col-lg-6">
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend input-group-sm">
                                <select class="custom-select" v-model="mode">
                                    <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                                        {{ e.name }}
                                    </option>
                                </select>
                            </div>
                            <input type="search" class="form-control" placeholder="ค้นหา / Seach" v-model="find"
                                v-on:keyup.enter="searchValueFromObject()" />
                            <button type="button" class="btn bt-main btn-sm mx-1 px-4" @click="searchValueFromObject()">
                                <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row m-0 pl-4 pb-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
                            จำนวน &nbsp;
                            <span class="text-main">
                                {{ new Intl.NumberFormat().format(totalRows) }}
                            </span>
                            <span class="ml-2">รายการ</span>
                        </p>
                        <div v-if="isLoading" class="col-12 col-xl-12 m-0 p-0 text-center">
                            <div class="p-xl-5 m-xl-5">
                                <b-spinner variant="info" small type="grow"></b-spinner>
                                <b-spinner variant="info" small type="grow"></b-spinner>
                                <b-spinner variant="info" small type="grow"></b-spinner>
                            </div>
                        </div>
                        <div v-else>
                            <b-table hover outlined show-empty class="form-control-sm font-0-7s" :items="items"
                                :empty-text="'ไม่พบข้อมูล / No Data'" :fields="fields" head-variant="light"
                                :current-page="page_num" :per-page="page_size">
                                <template #cell(Delete)="row">
                                    <span type="button" class="text-twd" v-html="$svg_icon.remove"
                                        @click="updateStock(row.item, 'Delete')"></span>
                                </template>
                            </b-table>
                        </div>

                        <div class="col-12">
                            <div class="row justify-content-end">
                                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                                    <select class="custom-select" v-model="page_size">
                                        <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                                            {{ e }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6 col-xl-3">
                                    <b-pagination v-model="page_num" :total-rows="totalRows" :per-page="page_size"
                                        align="fill" size="sm" class="my-0"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableListProduct",
    data() {
        return {
            mode: "skuId",
            find: '',
            isLoading: false,
            items: [],
            itemlist: [],
            totalRows: 0,
            page_size: 25,
            page_size_list: [25, 50, 100],
            page_num: 1,
            select_mode: [
                { key: 'skuId', name: 'รหัสสินค้า' },
            ],
            fields: [
                { key: "skuId", label: "SKU", class: "text-center", sortable: true },
                { key: 'DateStart', class: "text-center", sortable: true },
                { key: 'DateEnd', class: "text-center", sortable: true },
                { key: 'Delete', class: "text-center" }
            ],
        }
    },
    async mounted() {
        this.isLoading = true;
        await this.initTable();
        this.isLoading = false;

    },
    methods: {
        async initTable() {
            try {
                let getAPI = await this.$serviceAPI.call_API(
                    "post",
                    `market-place/getListStockRedText`,
                    [],
                    1
                );
                this.items = getAPI.data
                this.itemlist = getAPI.data;
                this.totalRows = this.items.length;
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }

        },
        async searchValueFromObject() {
            try {
                // console.log(this.find);
                this.items = this.itemlist.filter(item => {
                    const modeValue = item[this.mode];
                    if (typeof modeValue === 'string') {
                        return modeValue.toLowerCase().includes(this.find.toString().toLowerCase());
                    }
                    return false; // or true/false depending on how you want to handle non-string values
                });
                this.totalRows = this.items.length;
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }

        },
        async unSearchValueFromObject() {
            this.items = this.itemlist;
            this.totalRows = this.items.length;
        },
        async updateStock(item, mode) {
            try {
                const skuId = String(item.skuId).trim();
                const DateStart = String(item.DateStart).trim();
                const DateEnd = String(item.DateEnd).trim();

                const payloads = {
                    mode: mode,
                    jsonData: {
                        skuId: skuId,
                        DateStart: DateStart,
                        DateEnd: DateEnd
                    }
                }

                let confirm = await this.$serviceMain.showConfirmAlert(
                    this,
                    `SKU : ${skuId} ยืนยันการลบข้อมูล ? / Confirm Delete Data ?`
                );
                if (!confirm) return;

                let getAPI = await this.$serviceAPI.call_API(
                    "post",
                    `market-place/updateStockRedText`,
                    payloads,
                    1
                );
                if (getAPI.status_code === 200) {
                    this.$serviceMain.showSuccessAlert(
                        this,
                        "ลบข้อมูลสำเร็จ / Delete Success"
                    );
                    this.isLoading = true;
                    await this.initTable();
                    this.isLoading = false;
                } else {
                    throw new Error('ลบข้อมูลไม่สำเร็จ / Delete Fail');
                }
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }
        }
    },
    watch: {
        "find": function (v) {
            if (v === "") {
                this.unSearchValueFromObject();
            }
        }
    },
}
</script>
