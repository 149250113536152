<template>
    <div id="uploadStockPreorderAndRedText" class="container-fluid m-0 p-0">
        <div class="row m-0 p-3 justify-content-center">
            <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
                <div class="row m-0 p-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="font-1-2s font-600">Upload Stock Red Text</p>
                    </div>
                </div>
                <div class="row m-0 p-2">
                    <div class="col-12 ">
                        <b-tabs content-class="mt-2 m-0 p-0">
                            <b-tab title="List Stock Red Text">
                                <TableListStockRedText :key="tableKey" />
                            </b-tab>
                            <b-tab title="Upload Stock Red Text">
                                <UploadStockRedText @uploadSuccess="reloadTable" />
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableListStockRedText from '../../../components/MarketPlace/UploadStock/RedText/TableListStockRedText.vue'
import UploadStockRedText from '../../../components/MarketPlace/UploadStock/RedText/UploadStockRedText.vue'


export default {
    name: "UploadStockPreorderAndRedText",
    components: {
        TableListStockRedText,
        UploadStockRedText
    },
    data() {
        return {
            tableKey: 0
        }
    },
    methods: {
        reloadTable() {
            // Incrementing the tableKey to force re-rendering of the table component
            this.tableKey++;
        }
    },
}
</script>
